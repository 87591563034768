.section-combos-boxes {
    background-color: #fff; /* Cor de fundo laranja */
    background-image: url('../assets/images/basecombo.png'); /* Imagem de fundo */
    background-repeat: no-repeat; 
    background-size: cover;
    background-position: top center;
    padding: 20px;
    width: calc(100% - 40px); /* Leva em consideração o padding de 20px em cada lado */
    overflow: hidden; /* Para garantir que o pseudo-elemento não ultrapasse os limites da borda */
}

.section-combos-boxes .section-title {
    color: #Dd0000;
    text-align: center; /* Centraliza o texto */
    font-size: 30px;
    font-weight: bold;
    margin-top: 50px;
}

.section-combos-boxes .porapenas {
    color: #000000;
    text-align: left; /* Centraliza o texto */
    font-size: 10px;
    font-weight: bold;
}

.section-combos-boxes .cifrao {
    color: #000000;
    text-align: left; /* Centraliza o texto */
    font-size: 10px;
    font-weight: bold;
    margin-top: 50px;
    margin-left: 10px;
}

.section-combos-boxes .price {
    color: #Dd0000;
    font-size: 30px;
    font-weight: bold;
    margin-top: 50px;
    letter-spacing: -2px;
    margin: 0;
}

.section-combos-boxes .centavos {
    color: #Dd0000;
    font-weight: bold;
    margin-top: 50px;
    letter-spacing: -2px;
    margin-right: 10px;    
}

.section-combos-boxes .plus {
    color: #Dd0000;
    text-align: center; /* Centraliza o texto */
    font-size: 60px;
    font-weight: bold;

}

.section-combos-boxes .combospeed {
    color: #Dd0000;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    margin: 0;
    padding: 0;
    line-height: 30px; /* Centraliza verticalmente */
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100px; /* Tamanho fixo para a borda */
    height: 30px; /* Tamanho fixo para a borda */
    border: 1px solid #Dd0000;
    border-radius: 50px;
    margin: 0;
    padding: 0;
}

.section-combos-boxes .section-subtitle {
    color: #Dd0000;
    text-align: center; /* Centraliza o texto */
    font-size: 15px;
    font-weight: 100;
    margin: 0;
}

.column-wrapper {
    display: flex;
    width: 100%;
}


.listaprice {
    border-bottom: 1px solid #CCCCCC; /* Ajuste a cor e a espessura da borda conforme necessário */
  }

.right-column {
    flex: 1.3;
    display: flex;
    flex-direction: column;
    margin-top: 200px;
    align-items: flex-start; /* Alinha horizontalmente à esquerda */
}

.left-column {
    flex: 1;
    height: 600px;
    background-image: url('../assets/images/zigelateral.png'); /* Substitua pelo caminho da sua imagem de fundo */
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    /* Estilos para a coluna direita */
}

.list-button {
    font-size: 15px;
    background: linear-gradient(to right, #Dd0000, #5C0A0A);
    color: #ffffff;
    border: solid 1px;
    padding-left: 20px ;
    padding-right: 20px ;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 20px;
    cursor: pointer;
    margin: 20px;
    transition: background 0.3s ease; /* Alterei de background-color para background para animar o gradiente */

  }

.list-button:hover {
    border: solid 1px;
    background:  #ffffff; /* Inversão das cores no hover */
    color: #Dd0000
}
  
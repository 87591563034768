.section-auto-body {
    background-color: #fff;;
}

.section-auto-boxes {
    position: relative; /* Necessário para posicionar corretamente o pseudo-elemento */
    width: 90%;
    margin: 30px auto;
    padding: 20px;
    border-radius: 10px;
    overflow: hidden; /* Para garantir que o pseudo-elemento não ultrapasse os limites da borda */
}

.section-auto-boxes p {
    color: #fff;
    text-align: center; /* Centraliza o texto */
    margin: 0; /* Remove margens padrão */
}

.section-auto-boxes h1 {
    color: #fff;
    font-size: 28px;

}

.section-auto-boxes .section-title {
    margin-top: 170px;
    color: #Dd0000;
    margin-left: 30px;
    text-align: left; /* Centraliza o texto */
    font-size: 40px;

}

.section-auto-boxes .section-subtitle {
    color: #Dd0000;
    text-align: left; /* Centraliza o texto */
    margin-left: 30px;
    font-size: 15px;
    font-weight: 100;
    margin-bottom: 10px;
}

.section-auto-boxes .left-auto-column {
    width: 50%; /* Largura desejada para a coluna esquerda */
    padding: 20px; /* Espaçamento interno, se necessário */
    justify-content: center;
    align-items: center;
}

.section-auto-boxes .right-auto-column {
    width: 50%; /* Largura desejada para a coluna direita */
    padding: 20px; /* Espaçamento interno, se necessário */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.icon-section {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centralize os ícones horizontalmente dentro de cada seção */
    margin: 10px; /* Espaçamento entre as seções */
}

.icon-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px; /* Espaçamento entre os ícones */
  }

.icon-image {
    width: 150px; /* Defina o tamanho desejado para os ícones */

}

.icon-card {
    text-align: center;
    margin-left: 70px;
}

.icon-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly; /* Distribui o espaço uniformemente entre os ícones */
    align-items: center; /* Centralize os ícones horizontalmente */
}
.icon-row {
    display: flex;
    justify-content: space-between; /* Distribuir espaço uniformemente entre os ícones horizontalmente */
    align-items: center; /* Centralizar os ícones verticalmente */
    margin: 10px; /* Espaçamento entre as linhas de ícones */
}

.ajuda-button {
    font-size: 15px;
    background: linear-gradient(to right, #Dd0000, #5C0A0A); /* Gradiente de laranja claro (#FFA600) para laranja mais escuro (#FF6600) */
    color: #ffffff;
    border: solid 1px;
    padding-left: 20px ;
    padding-right: 20px ;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 20px;
    cursor: pointer;
    margin-bottom: 40px;
    transition: background 0.3s ease; /* Alterei de background-color para background para animar o gradiente */

  }

.ajuda-button:hover {
    border: solid 1px;
    background:  #ffffff; /* Inversão das cores no hover */
    color: #Dd0000
}
  
.section-uni-body {
    margin-top: -30px;
    background-color: #fff;
}

.section-uni-boxes {
    position: relative; /* Necessário para posicionar corretamente o pseudo-elemento */
    background-color: #ffffff; /* Cor de fundo laranja */
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    overflow: hidden; /* Para garantir que o pseudo-elemento não ultrapasse os limites da borda */
    
}

.section-uni-boxes p {
    color: #fff;
    text-align: center; /* Centraliza o texto */
    margin: 0; /* Remove margens padrão */
}

.section-uni-boxes h1 {
    color: #fff;
    font-size: 28px;

}

.section-uni-boxes .section-title {
    color: #Dd0000;
    margin-left: 30px;
    text-align: left; /* Centraliza o texto */
    font-size: 40px;

}

.section-uni-boxes .section-subtitle {
    color: #Dd0000;
    text-align: left; /* Centraliza o texto */
    margin-left: 30px;
    font-size: 15px;
    font-weight: 100;
    margin-bottom: 10px;
}

.section-uni-boxes .left-uni-column {
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Centraliza verticalmente */

}

.section-uni-boxes .right-uni-column {
    flex: 1;

}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* No seu arquivo de estilos CSS */
body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: inherit; /* Herda a imagem de background do body */
  background-size: cover;
  background-attachment: fixed;
  opacity: 0; /* Defina a opacidade inicial como 0 (totalmente transparente) */
  transition: opacity 0.5s ease; /* Adicione a transição para a opacidade */
  z-index: -1; /* Coloca o elemento pseudo atrás do conteúdo da página */
}

body::-webkit-scrollbar {
  width: 0.8em;               /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #5c0a0a88; /* Cor de fundo laranja */ /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #Dd0000;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 2px solid #FFA600;  /* creates padding around scroll thumb */
}